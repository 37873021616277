<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2">
                <h3>Новая должность</h3>
            </b-card-header>

            <work-positions-form v-model='workPosition'></work-positions-form>
        </b-card>

        <control-panel>
            <b-button variant="danger" @click="$router.go( -1 )">Отмена</b-button>
            <b-button variant="success" @click="createWorkPosition" v-if='isDiff'>Создать</b-button>
        </control-panel>
    </div>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep';

    import ControlPanel from '@components/_common/ControlPanel';
    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import WorkPositionsForm from './WorkPositionsForm';

    import ErrorsBag from '@utils/errorsBag';
    import { WORK_POSITIONS_ENDPOINT } from '@utils/endpoints';

    const DEFAULT_WORK_POSITION = {
        order: 0,
        key: null,
        name: null
    };

    export default {
        name: "WorkPositionsCreate",
        components: { WorkPositionsForm, ControlPanel, ErrorsBagList },
        computed: {
            isDiff() {
                return this.workPosition &&
                    JSON.stringify( this.workPosition ) !== JSON.stringify( DEFAULT_WORK_POSITION );
            }
        },
        data() {
            return {
                ErrorsBag,
                workPosition: null
            };
        },
        methods: {
            createWorkPosition() {
                ErrorsBag.discard();

                let params = {
                    key: this.workPosition.key,
                    name: this.workPosition.name
                };

                this.$http
                    .post( WORK_POSITIONS_ENDPOINT, params )
                    .then(
                        response =>
                            this.$router.push( { name: 'WorkPositionsEdit', params: { id: response.data.data.id } } ),
                        response => ErrorsBag.fill( response.body, false )
                    );
            }
        },
        mounted() {
            this.workPosition = cloneDeep( DEFAULT_WORK_POSITION );
        }
    }
</script>
